.paginated-devices {
  text-align: left;
  display: flex;
  flex-direction: column;
  font-family: Noto Sans;
  width: 100%;
  height: calc(100vh - 130px);

  .devices-top-area {
    @media screen and (max-width: 1442px) {
      padding: 32px 174px 16px;
    }

    @media screen and (min-width: 1443px) {
      padding: 32px 180px 16px;
    }
  }

  .devices-bottom-area {
    @media screen and (max-width: 1442px) {
      padding-left: 174px;
    }

    @media screen and (min-width: 1443px) {
      padding-left: 180px;
    }

    flex: 1;
    display: flex;
    overflow: hidden;

    &-contents {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      flex: 1;
      overflow: auto;
      scrollbar-gutter: stable;

      .pagination-container {
        min-width: 1080px;
      }

      @media screen and (max-width: 1442px) {
        padding: 0 170px 32px 0;
      }

      @media screen and (min-width: 1443px) {
        padding: 0 176px 32px 0;
      }
    }

    .table-responsive {
      margin-top: 0;
      overflow: initial;
      width: 100%;
    }

    thead {
      position: sticky;
      top: 0;
      z-index: 10;
      box-shadow: 0px -1px 0px white;
    }

    .grip-handle {
      z-index: 11;
    }
  }

  .devices-header {
    display: flex;
    flex-direction: column;
    gap: 4px;
    width: 100%;
    text-align: left;

    .devices-title {
      font-size: 24px;
      font-weight: 600;
      line-height: 32.69px;
      color: var(--greyscale_00);
      .edit-mode-title {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
      }
    }
  }

  .devices-filters {
    display: flex;
    height: max-content;
    justify-content: space-between;
    margin-top: 7px;
    flex-wrap: wrap;
    gap: 16px;

    .reload-filter-edit {
      width: max-content;
      min-width: 100px;
      height: 32px;
      padding: 7px 16px 8px 16px;
      gap: 2px;
      border-radius: 4px;
      border: 1px solid var(--button-tertiary-outline-default);
      align-items: center;
      display: flex;
      justify-content: center;
      color: rgba(var(--custom_color_17));
      text-align: center;
      font-family: "Noto Sans";
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      white-space: nowrap;

      &:hover {
        background-color: rgba(var(--custom_color_18));
      }

      &:active {
        border: 1px solid rgba(var(--custom_color_19));
        background: rgba(var(--custom_color_26));
      }
    }
    .disabled {
      border: 1px solid rgba(var(--custom_color_19));
      color: rgba(var(--custom_color_20));
      pointer-events: none;
    }
    .remove-device {
      width: 120px !important;
    }

    .left-side {
      display: flex;
      height: max-content;
      gap: 8px;
      align-items: center;
      font-size: 14px;
      line-height: 20px;
      text-align: left;
      flex-wrap: wrap;

      .count-container {
        font-weight: 400;
        display: flex;
        gap: 4px;

        .display-bold {
          font-weight: 700;
        }

        width: max-content;
      }

      .add-device-selector {
        width: 120px;

        &-toggle {
          &.dropdown-toggle {
            display: flex;
            padding: 0px 12px;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            gap: 8px;
            align-self: stretch;
            width: 100%;
            border: 1px solid var(--custom_color_16) !important;
            border-radius: 4px !important;
            background: initial;
            color: var(--custom_color_21);
            font-family: 'Noto Sans';
            font-size: 14px !important;
            font-weight: 400;
            line-height: 20px !important;
            height: 32px;

            &:active,
            &:focus,
            &:focus-visible,
            &:focus-within,
            &:hover {
              border: 1px solid var(--custom_color_16) !important;
              border-radius: 4px !important;
              background: initial !important;
              color: var(--custom_color_19) !important;
            }

            &.btn-outline-secondary {
              &.show {
                border: 1px solid var(--custom_color_16) !important;
                border-radius: 4px !important;
                background: initial;
                color: var(--custom_color_19);
              }
            }

            &::after {
              display: none !important;
            }
          }
        }

        &-menu {
          overflow-y: auto;
          width: 200px;
          max-height: 240px;
          padding: 4px;

          &.dropdown-menu {
            width: 100%;
            padding: 8px 0px;
            background: var(--brand_white);

            &.show {
              box-shadow: 0px 10px 15px -3px rgba(var(--greyscale-custom1), 0.1),
                0px 4px 6px -2px rgba(var(--greyscale-custom1), 0.05);
              border-radius: 4px;
            }
          }

          &-item {
            &.dropdown-item {
              width: 100%;
              display: flex;
              height: 32px;
              padding: 0px 8px;
              align-items: center;
              font-size: 14px;
              font-weight: 500;
              line-height: 20px;
              border: none;
              background: initial;
              color: var(--brand_black);
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;

              &.active {
                background: var(--hGreyscale_88);
                color: var(--brand_black);
                font-weight: 700;
              }

              &.disabled {
                opacity: 0.5;
              }

              &:first-child {
                border-top-left-radius: 0px;
                border-top-right-radius: 0px;
              }

              &:last-child {
                border-bottom-left-radius: 0px;
                border-bottom-right-radius: 0px;
              }
            }

            &.dropdown-item:hover {
              background: rgba(var(--custom_color_26));
              border-top-left-radius: 0px;
              border-top-right-radius: 0px;
              border-bottom-left-radius: 0px;
              border-bottom-right-radius: 0px;
            }
          }
        }

        .add-device-selector {
          &-wrapper {
            display: inline-flex;
            align-items: center;
            border-radius: 4px;
          }

          &-name {
            margin-left: 0px !important;
          }
        }
      }
    }

    .right-side {
      display: flex;
      height:  max-content;
      gap: 8px;
      align-items: center;
      font-size: 14px;
      line-height: 20px;
      text-align: left;
      flex-wrap: wrap;

      .search-container {
        display: flex;
        text-align: center;
        align-items: center;
        width: 320px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        align-self: stretch;
        border-radius: 4px;
        border: 1px solid var(--custom_color_16);
        background: initial;
        position: relative;

        & .search-icon {
          z-index: 9;
          margin: 4px 8px 4px 4px;
          position: absolute;
          left: 0;
        }

        & .search-input {
          padding: 6px 8px 6px 32px;
          color: var(--greyscale_00);
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 20px;
          background: initial;
          width: 310px;
          height: 30px;
          border-radius: none;
          border: none;

          &:hover {
            background: initial !important;
            color: var(--greyscale_40) !important;
            border-color: var(--dark_orange);
          }

          &:focus {
            color: var(--bs-body-color);
            background-color: var(--bs-body-bg);
            border-color: var(--greyscale_buttons_2); // ToDo: Need to Fix
            outline: 0;
          }
        }

        & .search-close {
          position: absolute;
          cursor: pointer;
          right: 0;
          margin: 8px;
        }
      }
    }
  }

  .hide {
    visibility: hidden;
  }

  .grip-drag {
    background-color: var(--brand_primary);
    opacity: 1;
    width: 1px;
    border-color: var(--brand_primary);
  }

  .grip-handle {
    margin-left: -2px;
  }


  /* Table styles */

  .table {
    border-collapse: collapse;
    width: 100%;
    min-width: 1080px !important;
    margin-bottom: 4px;
  }

  .table-responsive {
    justify-content: flex-start;

    .grip-container {
      min-width: 1080px !important;
    }
  }

  .table thead,
  .table th {
    padding: 0px 0px 0px 10px;
    gap: 10px;
    background: var(--custom_color_22);
    color: var(--text_color);
    align-items: center;
    text-align: start;
    vertical-align: middle;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    .fixed-column {
      width: 29px !important;
    }

    .header-applications-linked {
      width: 241px !important;
    }

    .arrow {
      width: 100%;
      gap: 4px;
      height: 19px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .checkbox {
      width: 100%;
      gap: 4px;
      height: 19px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .deviceName {
      width: 100%;
      gap: 4px;
      height: 19px;
      border-right: 1px solid var(--custom_color_23);
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .modelNumber {
      width: 100%;
      gap: 4px;
      height: 19px;
      border-right: 1px solid var(--custom_color_23);
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .serialNo {
      width: 100%;
      gap: 4px;
      height: 19px;
      border-right: 1px solid var(--custom_color_23);
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .status {
      width: 100%;
      gap: 4px;
      height: 19px;
      border-right: 1px solid var(--custom_color_23);
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .location {
      width: 100%;
      gap: 4px;
      height: 19px;
      border-right: 1px solid var(--custom_color_23);
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .area {
      width: 100%;
      gap: 4px;
      height: 19px;
      border-right: 1px solid var(--custom_color_23);
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .applications-linked {
      width: 100%;
      gap: 4px;
      height: 19px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .ipAddress {
      width: 100%;
      gap: 4px;
      height: 19px;
      border-right: 1px solid var(--custom_color_23);
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .createdDate {
      width: 100%;
      gap: 4px;
      height: 19px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .connectionStatus {
      width: 100%;
      gap: 4px;
      height: 19px;
      border-right: 1px solid var(--custom_color_23);
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }


  .table thead,
  .table tbody,
  .table tfoot,
  .table tr,
  .table td,
  .table th {
    text-align: start;
    height: 48px;
    vertical-align: middle;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
  }

  .table {
    thead {
      height: 46px;

      tr,
      th {
        height: 46px;
      }
    }
  }

  thead,
  .table> :not(:first-child) {
    border-top: 0;
  }

  .table thead th {
    color: var(--text_color);
    height: 46px;
    background: var(--custom_color_22);
  }

  .table tbody td {
    border-style: none none none none;
    color: rgba(var(--custom_color_17));
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 0px 10px 0px 10px;

    .margin-rt {
      margin-right: 8px;
    }

    .margin-lt-20 {
      margin-left: 20px;
    }

  }

  .table tbody tr {
    background: initial;
    height: 48px;
    padding: 0px 20px 0px 12px;
    border-style: solid solid solid solid;
    border: 1px solid var(--custom_color_23);
    cursor: auto;

    .hover-icons {
      visibility: hidden;
    }

    .site-spinner {
      padding: 2px;
    }

    .margin-rt-4 {
      margin-right: 4px;
    }
    .no-device-container {
      text-align: center;
      background-color: var(--brand_white);
      height: calc(100vh - 400px);
      text-align: center;

      .no-device-content {
        display: flex;
        flex-direction: column;
        text-align: center;
        font-family: "Noto Sans";
        font-style: normal;
        padding: 0px;

        .no-device-heading {
          font-size: 16px;
          font-weight: 600;
          line-height: 16px;
          color: var(--brand_black);
        }
        .no-device-text {
          color: rgba(var(--custom_color_28));
          text-align: center;
          font-family: "Noto Sans";
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 22px; /* 137.5% */
        }
      }
    }
     .loader-container {
      text-align: center;
      background-color: var(--brand_white);
      height: calc(100vh - 400px);

      .site-spinner {
        padding: 2.5px;
      }
    }

    &:hover {
      background-color: var(--brand_white);
    }
  }

  .table .row-bg {
    &:hover {
      background-color: rgba(var(--custom_color_18));

      .hover-icons {
        visibility: visible;
      }
    }
  }

  .table thead tr {
    padding: 0px 20px 0px 12px;
  }


  .table tbody td svg {
    width: auto;
    height: auto;
    flex: none;
  }

  .table tr td:first-child {
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
    padding-left: 12px;
    padding-right: 2px;
  }

  .table tr td:last-child {
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    padding-right: 20px;
  }

  .table tr th:first-child {
    border-top-left-radius: 8px;
    border-bottom-left-radius: 0px;
    padding-left: 12px;
  }

  .table tr th:last-child {
    border-top-right-radius: 8px;
    border-bottom-right-radius: 0px;
    padding-right: 20px;
  }

  .table tr:first-child td {
    border-top-style: none;
  }

  .table tr td:first-child {
    border-left-style: solid;
  }

  .table .columnheader-asc span,
  .table .columnheader-desc span {
    margin-left: 12px;
  }

  .table .columnheader-desc {
    color: var(--primary_40);
  }

  .table .columnheader-desc svg path {
    stroke: var(--primary_40);
  }

  .table .columnheader-asc {
    color: var(--greyscale_56);
  }

  .table .columnheader-asc svg path {
    stroke: var(--greyscale_56);
  }

  .deviceIcon {
    height: 32px;
    padding: 8px;
    cursor: pointer;
    border: 1px solid var(--greyscale_80);
    margin: 0 8px 0 0;
    border-radius: 4px;
    width: 50px;
  }

  .appActiveOnCloud {
    border: 1px solid var(--primary_40);
    background-color: var(--primary_96);
    cursor: pointer;
  }

  .appActiveWithoutOnCloud {
    border: 1px solid var(--primary_40);
    background-color: var(--primary_96);
    cursor: default;
  }

  .appDisableWithoutOnCloud {
    cursor: default;
  }

  .parent-name {
    color: var(--brand_black) !important;
    font-weight: 600 !important;
  }

  .device-name-color {
    color: var(--brand_black) !important;
  }

  .load-more {
    display: flex;
    height: 32px;
    padding: 3px 20px;
    align-items: center;
    gap: 2px;
    border-radius: 4px;
    border: 1px solid rgba(var(--custom_color_24));
    background: initial;
    color: var(--brand_black);
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    /* 142.857% */
    width: max-content;
    margin-top: 8px;
    margin-bottom: 8px;

    &:hover {
      background-color: rgba(var(--custom_color_18));
    }

    &:active {
      border: 1px solid rgba(var(--custom_color_19));
      background: rgba(var(--custom_color_26));
    }

    &:disabled {
      border: 1px solid rgba(var(--custom_color_19));
      color: rgba(var(--custom_color_20));
      pointer-events: none;
    }
  }

  .load-more-disabled {
    border: 1px solid rgba(var(--custom_color_19)) !important;
    color: rgba(var(--custom_color_20)) !important;
    pointer-events: none !important;
  }

  .load-more-child {
    margin-left: 40px;
  }

  .load-more-parent {
    margin-left: 12px;
  }

  .paginated-online-device {
    color: var(--custom_color_25) !important;
    font-weight: 500 !important;
  }

  .paginated-deactiavted-device {
    color: var(--hGreyscale_40) !important;
    font-weight: 500 !important;
  }

  .paginated-claiming-device {
    color: var(--brand_primary) !important;
    font-weight: 500 !important;
  }

  .paginated-offline-device {
    color: var(--hGreyscale_40) !important;
    font-weight: 500 !important;
  }

  .expanded-parent-row {
    background-color: rgba(var(--custom_color_18)) !important;
  }

  .disabled-row {
    border-top: 1px solid rgba(var(--custom_color_26));
    opacity: 0.5;
    pointer-events: none;
  }

  .fw-update-icon {
    width: 20px;
    height: 16px;
    border-radius: 4px;
    border: 1px solid var(--brand_primary);
    background: initial;
    color: var(--brand_primary);
    text-align: center;
    font-size: 11px;
    font-style: normal;
    font-weight: 900;
    padding: 0px 2px;
    margin-left: 4px;

    &:hover {
      background-color: var(--brand_primary);
      color: var(--brand_white);
    }
  }

  .paginated-device-name {
    text-overflow: inherit !important;
  }

  .truncated-device-name {
    display: flex;
    align-items: center !important;
  }

  .searchStyles {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    max-width: 80%;
  }

  .searched-text {
    color: var(--primary_40);
    font-weight:700;
  }

  .device-icon-width {
    width: 32px !important;
  }

  .delete-checkbox input[type='checkbox'] {
    height: 16px;
    width: 16px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 2px;
    border: 1.5px solid var(--greyscale_80);
    background: initial;
    appearance: none;
    cursor: pointer;
    position: relative;
    margin-right: 10px;
  }

  .delete-checkbox input[type='checkbox']:disabled {
    height: 16px;
    width: 16px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 2px;
    border: 1.5px solid var(--greyscale-40);
    background: var(--greyscale_48);
    appearance: none;
    cursor: auto;
    margin-right: 10px;
  }

  .delete-checkbox input[type='checkbox']:checked::after {
    position: absolute;
    width: 16px;
    height: 16px;
    color: var(--brand_white);
    content: '\2713';
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    border: none;
    border-radius: 2px;
    background: var(--primary_40);
    cursor: pointer;
  }
  .edit-checkbox input[type='checkbox'] {
    height: 16px;
    width: 16px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 2px;
    border: 1.5px solid var(--greyscale_80);
    background: initial;
    appearance: none;
    cursor: pointer;
    position: relative;
    margin-right: 10px;
  }

  .edit-checkbox input[type='checkbox']:disabled {
    height: 16px;
    width: 16px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 2px;
    border: 1.5px solid var(--greyscale-40);
    background: var(--greyscale_48);
    appearance: none;
    cursor: auto;
    margin-right: 10px;
  }

  .edit-checkbox input[type='checkbox']:checked::after {
    content: '';
    display: block;
    align-items: center;
    justify-content: center;
    background: var(--brand_white);
    cursor: pointer;
    border: 3px solid var(--brand_primary);
    border-radius: 4px;
  }
  .edit-inner-checkbox {
    left: 5px;
    bottom: 5px;
    width: 6px;
    height: 6px;
    position: absolute;
    background-color: var(--brand_primary);
    display: flex;
    border-radius: 2px;
  }
  .edit-checkbox{
    position: relative;
  }

  .edit-name-container {
    width: 234px;
    min-width: 234px;
    max-width: 234px;
    height: 32px;
    padding: 5px 4px 6px 8px;
    gap: 2px;
    text-align: center;
    flex-direction: row;
    align-items: center;
    align-self: stretch;
    border-radius: 4px;
    border: 1px solid var(--brand_black);
    background: initial;
    position: relative;
    justify-content: space-between;
    margin-top: 8px;

    input[type="search"]::-webkit-search-cancel-button {
      -webkit-appearance: none;
      width: 20px;
      height: 20px;
      background: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M2 8C2 11.3137 4.68629 14 8 14C11.3137 14 14 11.3137 14 8C14 4.68629 11.3137 2 8 2C4.68629 2 2 4.68629 2 8ZM6.36185 10.5019C6.16659 10.6972 5.85001 10.6972 5.65475 10.5019C5.45949 10.3067 5.45949 9.99007 5.65475 9.79481L7.42221 8.02734L5.65422 6.25935C5.45896 6.06409 5.45896 5.74751 5.65422 5.55224C5.84948 5.35698 6.16606 5.35698 6.36133 5.55224L8.12932 7.32024L9.89739 5.55217C10.0927 5.35691 10.4092 5.35691 10.6045 5.55217C10.7998 5.74743 10.7998 6.06401 10.6045 6.25927L8.83643 8.02734L10.604 9.79488C10.7992 9.99015 10.7992 10.3067 10.604 10.502C10.4087 10.6973 10.0921 10.6973 9.89686 10.502L8.12932 8.73445L6.36185 10.5019Z' fill='%23CCCFD1'/%3E%3C/svg%3E%0A")
      center center no-repeat;
      cursor: pointer;
    }

    & .cross-icon {
      z-index: 9;
      margin: 7px 4px 4px 16px;
      position: absolute;
      right: 0;
      top: 0;
    }

    & .save-name-icon {
      z-index: 9;
      margin: 4px 8px 4px 16px;
      position: absolute;
      right: 0;
    }

    & .rename-input {
      padding: 0px 1px 1px 0px;
      color: var(--brand_black);
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      /* 142.857% */
      width: 220px;
      height: 30px;
      border-radius: none;
      border: none;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      background: initial;

      &:hover {
        background: initial !important;
        color: var(--brand_black) !important;
        border-color: var(--dark_orange);
        outline: 0;
      }

      &:focus {
        background: initial !important;
        color: var(--brand_black) !important;
        outline: 0;
      }
    }

    & .search-close {
      width: 16px;
      height: 16px;
      position: absolute;
      cursor: pointer;
      right: 16px;
    }
    & .renaming {
      width: 204px;
      padding-right: 4px;
    }
  }
  .toggle-container {
    display: flex;
    align-items: center;
    margin-bottom: 9px;
    .claim-selected {
      display: flex;
      height: 32px;
      min-width: 124px;
      padding: 0px 12px;
      justify-content: center;
      align-items: center;
      gap: 4px;
      border-radius: 4px 0px 0px 4px;
      background: var(--Button-Secondary-Default, rgba(0, 0, 0, 0.14));
      color: var(--brand_black);
      text-align: center;
      font-family: "Noto Sans";
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height:20px; /* 142.857% */
    }
    .claim-unselected {
      display: flex;
      height: 32px;
      min-width: 124px;
      padding: 0px 12px;
      justify-content: center;
      align-items: center;
      gap: 4px;
      border-radius: 4px 0px 0px 4px;
      border-top: 1px solid var(--Button-Tertiary-Outline-Default, rgba(0, 0, 0, 0.21));
      border-left: 1px solid var(--Button-Tertiary-Outline-Default, rgba(0, 0, 0, 0.21));
      border-bottom: 1px solid var(--Button-Tertiary-Outline-Default, rgba(0, 0, 0, 0.21));
      color: rgba(var(--custom_color_32));
      text-align: center;
      font-family: "Noto Sans";
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height:20px; /* 142.857% */
    }
    .reclaim-selected {
      display: flex;
      height: 32px;
      min-width: 124px;
      padding: 0px 12px;
      justify-content: center;
      align-items: center;
      gap: 4px;
      border-radius: 0px 4px 4px 0px;
      background: var(--Button-Secondary-Default, rgba(0, 0, 0, 0.14));
      color: var(--brand_black);
      text-align: center;
      font-family: "Noto Sans";
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height:20px; /* 142.857% */
    }
    .reclaim-unselected {
      display: flex;
      height: 32px;
      min-width: 124px;
      padding: 0px 12px;
      justify-content: center;
      align-items: center;
      gap: 4px;
      border-radius: 0px 4px 4px 0px;
      border-top: 1px solid var(--Button-Tertiary-Outline-Default, rgba(0, 0, 0, 0.21));
      border-right: 1px solid var(--Button-Tertiary-Outline-Default, rgba(0, 0, 0, 0.21));
      border-bottom: 1px solid var(--Button-Tertiary-Outline-Default, rgba(0, 0, 0, 0.21));
      color: rgba(var(--custom_color_32));
      text-align: center;
      font-family: "Noto Sans";
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height:20px; /* 142.857% */
    }
  }
  .pagination-container {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 20px;
    align-self: stretch;
    margin-top: 16px;
    flex-wrap: wrap;

    .go-to-page {
      color: rgba(var(--custom_color_17));
      font-family: "Noto Sans";
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 10px;

      .input-page {
        display: flex;
        height: 32px;
        align-items: center;
        gap: 8px;
        border-radius: 4px;
        border: 1px solid rgba(var(--custom_color_19));
        background: var(--brand_white);
        & .page-input {
          padding: 0px 8px;
          color: rgba(var(--custom_color_17));
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 20px;
          background: initial;
          width: 100%;
          max-width: 50px;
          min-width: 50px;
          height: 30px;
          border-radius: none;
          border: none;

          &:hover {
            background: initial !important;
            color: var(--greyscale_40) !important;
            border-color: var(--dark_orange);
          }

          &:focus {
            color: var(--bs-body-color);
            background-color: var(--bs-body-bg);
            border-color: var(--greyscale_buttons_2); // ToDo: Need to Fix
            outline: 0;
          }
        }
        input::-webkit-outer-spin-button,
        input::-webkit-inner-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }

        /* Firefox */
        input[type=number] {
          -moz-appearance: textfield;
        }
      }
      .Mui-selected {
          background-color: var(--brand_primary)
      }
    }
    .total {
      color:  rgba(var(--custom_color_17));
      font-family: "Noto Sans";
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      display: flex;
      gap: 4px;
    }
    .Mui-selected {
      background-color: var(--brand_primary);
      color: var(--brand_white) !important;
      &:hover{
        background-color: var(--dark_orange_1);
        color: var(--brand_white);
      }
    }
    .MuiPaginationItem-rounded {
      display: flex;
      min-width: 24px;
      height: 24px;
      padding: 3px 0px;
      justify-content: center;
      align-items: center;
      border-radius: 4px;
      color:  rgba(var(--custom_color_17));
      text-align: center;
      font-family: "Noto Sans";
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 18px;
    }
  }
  .update-location-usermsg-container {
    display: flex;
    gap: 4px;
    align-items: center;
    flex-wrap: wrap;

    .success {
      color: var(--custom_color_25);
      font-family: "Noto Sans";
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }
    .failure{
      color: var(--brand_black);
      font-family: "Noto Sans";
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }
}

.paginated-device-page {
  width: 100%;
  justify-content: flex-start;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.loader-style {
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  color: var(--greyscale_56);
  top: 55%;
}
._loading_overlay_overlay{
  background: rgba(0, 0, 0, 0.01);
}
.screen-loader-container {
  text-align: center;
  background-color: var(--brand_white);
  height: 0px;

  .site-spinner {
    padding: 2.5px;
  }
}
