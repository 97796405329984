.filter-checkbox {
  width: 16px;
  height: 16px;

  &-default {
    width: 100%;
    height: 100%;
    border-radius: 4px;
    border: 1px solid var(--icon-normal-secondary);

    &:hover {
      background-color: var(--background-interaction-hover);
      border: 1px solid var(--icon-normal-primary);
    }
  }

  svg {
    vertical-align: initial;

    &:hover {
      opacity: 77%;
    }
  }
}
