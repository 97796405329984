@import '../../../assets/css/base';

.save-view-modal,
.save-as-new-view-modal {
  .modal-header {
    padding-bottom: 0;
  }

  .modal-dialog .modal-content {
    padding: 16px 32px;

    .save-view-modal-description {
      color: var(--greyscale_56);
      font-size: toRem(14px);
      padding-bottom: 16px;
    }

    .view-name-input-wrapper {
      display: flex;
    }

    .view-name-input {
      display: flex;
      background-color: var(--brand_white);
      border: 1.5px solid var(--greyscale_88);
      border-radius: 12px;
      padding: 12px 16px;
      align-items: flex-start;
      width: 100%;

      &:focus {
        outline: none;
      }
    }

    .save-view-button-wrapper {
      padding-top: 10px;
      padding-bottom: 10px;
    }
  }
}

.update-view-modal {
  .modal-dialog .modal-content {
    width: 542px;

    .modal-header {
      padding-top: 0;
    }

    .modal-body {
      padding-bottom: 0;

      .update-view-button-wrapper {
        display: flex;
        justify-content: flex-end;
        margin-top: 12px;

        .update-btn {
          font-family: 'Noto Sans';
          font-size: toRem(14px);
          font-weight: 600;
          height: 44px;
          margin-right: 8px;
        }
      }
    }
  }
}

.create-clip-modal {
  .modal-header {
    padding-top: 0;
  }

  .modal-body {
    .create-clip {
      &-snapshot-wrapper {
        aspect-ratio: 16/9;
        width: 478px;
        border-radius: 12px;

        .image-wrapper {
          img {
            width: 100%;
            aspect-ratio: 16/9;
            border-radius: 12px;
          }
        }

        .create-clip-video-processing {
          border-radius: 12px;
          height: 100%;
          width: 100%;

          .spinner {
            position: absolute;
            z-index: 20;
            top: 18%;
            left: calc(50% + 12px);
          }

          &-snapshot {
            border-radius: 12px;
            position: absolute;

            img {
              width: 100%;
              aspect-ratio: 16/9;
            }
          }

          &-status {
            position: absolute;
            z-index: 10;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            min-height: 268px;
            width: 100%;
            margin-top: 0px;
            margin-left: auto;
            margin-right: auto;
            border-radius: 12px;
            background: rgba(0, 16, 41, 0.6);

            &-title {
              color: var(--brand_white);
              text-align: center;
              font-size: toRem(14px);
              font-weight: 600;
              line-height: toRem(20px);
              margin-bottom: 8px;
              text-align: center;
            }

            &-message {
              color: var(--primary_88);
              font-size: toRem(14px);
              font-weight: 400;
              line-height: normal;
              text-align: center;
              width: 292px;
            }
          }
        }
      }

      &-notification-wrapper {
        .notification {
          display: flex;
          flex-direction: column;
          padding: 12px;
          align-items: flex-start;
          gap: 6px;
          align-self: stretch;
          border-radius: 12px;

          &.success {
            background: var(--success_96);

            .success-title-wrapper {
              .success-title {
                color: var(--success_24);
                font-size: toRem(16px);
                font-weight: 600;
                line-height: toRem(20px);
                margin-left: 8px;
              }
            }
          }

          &.error {
            border-radius: 12px;
            background: var(--error_96);

            .error-title-wrapper {
              display: flex;
              justify-content: flex-start;
              align-items: center;

              .error-title {
                color: var(--error_24);
                font-size: toRem(16px);
                font-weight: 600;
                line-height: toRem(18px);
                margin-left: 8px;
              }
            }

            .error-description {
              color: var(--error_24);
              font-size: toRem(14px);
              font-weight: 400;
              line-height: toRem(16px);
              padding-left: 28px;
            }
          }
        }
      }

      &-incidents-wrapper {
        .incident-select {
          .incident-item-wrapper {
            display: flex;
            justify-content: space-between;
            align-items: center;
          }
        }
      }

      &-shortcuts-wrapper {
        color: var(--brand_black);
        font-size: toRem(16px);
        font-weight: 600;
        line-height: toRem(20px);
      }

      &-shortcut-icon {
        display: flex;
        padding: 20px 16px;
        // flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        gap: 8px;
        flex: 1 0 0;
        border-radius: 12px;
        border: 1.5px solid var(--greyscale_88);
        background: var(--brand_white);
        width: 100%;
        color: var(--greyscale_40);
        font-size: toRem(14px);
        font-weight: 500;
        line-height: toRem(20px);
        text-transform: none;

        &:hover {
          border: 1.5px solid var(--greyscale_88);
          background: var(--grayscale_96);
        }

        &:disabled {
          border: 1.5px solid var(--greyscale_88);
          opacity: 0.3;
          background: var(--brand_white);
        }

        span {
          margin-right: 0;
        }
      }
    }

    .clip {
      &-duration-wrapper {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        gap: 16px;
        align-self: stretch;
      }

      &-time {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 8px;
        flex: 1 0 0;
      }

      &-duration-time {
        color: var(--brand_black);
        font-size: toRem(16px);
        font-weight: 600;
        line-height: toRem(20px);

        &-box {
          display: flex;
          padding: 16px 24px;
          flex-direction: column;
          justify-content: center;
          align-items: flex-start;
          gap: 2px;
          align-self: stretch;
          border-radius: 10px;
          background-color: var(--grayscale_96);

          .clipper-date {
            color: var(--greyscale_08);
            font-size: toRem(16px);
            font-weight: 400;
            line-height: toRem(20px);
          }

          .clipper-time-with-timezone {
            color: var(--greyscale_08);
            font-size: toRem(18px);
            font-weight: 600;
            line-height: toRem(24px);
          }
        }
      }

      &-start-time {}

      &-end-time {}

      &-comment {
        border-radius: 12px;
        border: 1.5px solid var(--greyscale_88);

        .MuiInputBase-multiline .MuiInputBase-input.MuiOutlinedInput-input.MuiInputBase-inputMultiline::placeholder {
          color: var(--greyscale_40);
          opacity: 1;
          font-size: toRem(16px);
          font-weight: 500;
          line-height: toRem(20px);
        }

        &:hover {
          border-color: var(--greyscale_88);
        }
      }

      &-tags {
        &-title {
          color: var(--greyscale_08);
          font-size: toRem(20px);
          font-weight: 600;
          line-height: toRem(28px);
        }

        &-content {}
      }
    }

    .char-count {
      font-size: toRem(12px);
      line-height: toRem(20px);
      text-align: right;
    }
  }
}

.device-setup-modal {
  .modal-dialog.modal-md.modal-dialog-centered {
    .modal-content {
      padding: 32px !important;
    }
  }

  .modal-dialog .modal-content {
    width: 542px;
    min-width: 542px;
    padding: 32px !important;

    .modal-body {
      padding-bottom: 0;

      .device-setup-modal-wrapper {
        display: flex;
        align-items: flex-start;
        flex-direction: column;
      }

      .css-90a3vc-MuiButtonBase-root-MuiRadio-root {
        padding: 0px;
      }

      .css-90a3vc-MuiButtonBase-root-MuiRadio-root.Mui-checked {
        padding: 0px;
      }

      .css-mkl144 {
        padding: 0px;
      }

      .css-mkl144.Mui-checked {
        padding: 0px;
        color: var(--primary_40) !important;
      }

      .MuiFormGroup-root .MuiRadio-root {
        padding: 0px;

        &.Mui-checked {
          color: var(--primary_40);
        }
      }

      .setup-loader {
        color: var(--brand_white);
      }

      & .back-arrow-button {
        display: flex;
        align-items: center;
        gap: 4px;
        color: var(--primary_40);
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
        margin-bottom: 4px;
        width: fit-content;
        cursor: pointer;
      }

      & .back-arrow-button-disabled {
        display: flex;
        align-items: center;
        gap: 4px;
        color: var(--primary_88);
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
        margin-bottom: 4px;
        width: fit-content;
        cursor: auto;
      }

      .step-icon {
        width: 40px;
        height: 4px;
        border-radius: 40px;
        background: var(--primary_40);
        margin-right: 8px;
      }

      .step-icon-disabled {
        width: 40px;
        height: 4px;
        border-radius: 40px;
        background: var(--primary_88);
        margin-right: 8px;
      }

      .search-container {
        display: flex;
        text-align: center;
        align-items: center;
        width: 100%;

        & .search-icon {
          position: absolute;
          z-index: 9;
          margin: 12px 8px 12px 16px;
        }

        & .search-input {
          display: flex;
          padding: 16px 20px 16px 40px;
          flex-direction: column;
          align-items: flex-start;
          gap: 8px;
          align-self: stretch;
          border-radius: 10px;
          border: 1.5px solid var(--hGreyscale_56);
          background: var(--brand_white);
          color: var(--greyscale-40);
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 20px;
          width: 100%;

          &:hover {
            background-color: var(--brand_white) !important;
            color: var(--greyscale_40) !important;
            border-color: var(--dark_orange);
          }

          &:focus {
            color: var(--bs-body-color);
            background-color: var(--bs-body-bg);
            border-color: var(--greyscale_buttons_2); // ToDo: Need to Fix
            outline: 0;
          }
        }
      }

      .selected-opt {
        display: flex;
        padding: 4px 8px;
        align-items: center;
        gap: 6px;
        border-radius: 8px;
        background: var(--primary_96);
        color: var(--primary_32);
        font-size: 12px;
        font-style: normal;
        font-weight: 600;
        line-height: 16px;
        margin-left: 8px;
      }

      .discription {
        color: var(--greyscale_56);
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        margin-top: 16px;
      }

      .header-container {
        display: flex;
        justify-content: space-between;
        flex-direction: row;
        width: 100%;

        .header-image {
          width: 100%;
          display: flex;
          justify-content: center;
        }
      }

      .stepIcon>img {
        margin-left: 0;
        margin-top: 0;
      }

      .device-setup-modal-title {
        color: var(--greyscale_08);
        font-size: 20px;
        line-height: 28px;
        font-weight: 700;
        text-align: center;
      }

      .device-setup-modal-subTitle {
        color: var(--brand_black);
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 20px;
      }

      .location-conatiner {
        display: flex;
        max-height: 273px;
        padding: 12px 20px;
        flex-direction: column;
        align-items: flex-start;
        gap: 8px;
        align-self: stretch;
        border-radius: 12px;
        background: var(--greyscale_96);
        color: var(--greyscale_08);
        overflow: auto;

        .location-item {
          display: flex;
          padding: 6px 0px;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          gap: 4px;
          align-self: stretch;
          color: var(--greyscale_08);
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 20px;
        }
      }

      .css-90a3vc-MuiButtonBase-root-MuiRadio-root.Mui-checked {
        padding: 0px;
      }

      .site-modal-close {
        cursor: pointer;
        color: var(--dark_gray);
        padding: 1.5px;
        font-weight: 600;
        stroke-width: 1;
      }

      .site-modal-close:hover {
        color: var(--button-color-1);
      }

      .site-modal-close:active {
        color: var(--dark_orange);
      }
    }
  }
}

.delete-device-modal {
  .modal-header {
    padding-bottom: 0;
    padding-top: 22px;
  }

  .modal-dialog .modal-content {
    padding: 32px !important;

    .delete-device-modal-description {
      color: var(--greyscale_56);
      font-size: toRem(14px);
      padding-bottom: 16px;
    }

    .delete-device-button-wrapper {
      padding-top: 8px;
      padding-bottom: 8px;
    }

    .delete-device-loader {
      background: var(--error_64);
    }
  }
}

.device-filter-modal {
  .modal-dialog.modal-lg.modal-dialog-centered {
    display: flex;
    justify-content: center;
  }

  .modal-dialog .modal-content {
    width: 935px;
    min-width: 935px;
    padding: 24px !important;

    .modal-body {
      padding-bottom: 0;

      .device-filter-modal-wrapper {
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        justify-content: center;
      }

      .filter-checkbox input[type='checkbox'] {
        height: 14px;
        width: 14px;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 2px;
        border: 1.5px solid var(--greyscale_80);
        background: var(--brand_white);
        appearance: none;
        cursor: pointer;
        position: relative;
      }

      .filter-checkbox input[type='checkbox']:disabled {
        height: 14px;
        width: 14px;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 2px;
        border: 1.5px solid var(--greyscale-40);
        background: var(--greyscale_48);
        appearance: none;
        cursor: auto;
      }

      .filter-checkbox input[type='checkbox']:checked::after {
        position: absolute;
        width: 14px;
        height: 14px;
        color: var(--brand_white);
        content: '\2713';
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 600;
        border: none;
        border-radius: 2px;
        background: var(--primary_40);
        cursor: pointer;
      }

      .filter-table-structure {
        display: flex;
        align-items: flex-start;
        gap: 13px;

        .filter-column {
          display: flex;
          width: 212px;
          height: 197px;
          flex-direction: column;
          align-items: flex-start;
          gap: 15px;

          .hide {
            visibility: hidden;
          }

          .filter-container {
            display: flex;
            width: 212px;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
          }

          .filter-row-container {
            display: flex;
            height: 32px;
            padding: 0px 8px;
            align-items: center;
            gap: 8px;
            align-self: stretch;
            color: rgba(var(--custom_color_17));
            font-family: "Noto Sans";
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            width: max-content;
          }

          .filter-header {
            color: var(--greyscale_00);
            font-family: "Noto Sans";
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
          }
        }

        .filter-column-border {
          border-right: 1px solid rgba(var(--custom_color_27));
        }
      }

      .filter-footer {
        padding: 20px 8px 0px 8px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 100%;

        .left-section {
          display: flex;
          flex-direction: row;
          align-items: flex-start;
          color: rgba(var(--custom_color_28));
          text-align: center;
          font-family: "Noto Sans";
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          align-items: center;
          gap: 4px;

          .rotate-reload {
            transform: rotateX(180deg);
          }
        }

        .right-section {
          display: flex;
          flex-direction: row;
          align-items: flex-end;
          gap: 8px;
        }
      }
    }
  }
}

.location-area-selector-modal {
  .modal-dialog.modal-dialog-centered {
    display: flex;
    justify-content: center;
  }

  .modal-dialog .modal-content {
    width: 545px;
    min-width: 545px;
    padding: 16px 24px 24px 24px !important;
  }

  .location-area-selector-modal-wrapper {
    display: flex;
    align-items: flex-start;
    flex-direction: column;

    .modal-title {
      color: var(--brand_black);
      /* 14 regular */
      font-family: "Noto Sans";
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      margin-top: 20px;
    }

    .dropdown-title {
      color: var(--brand_black);

      /* 14 semi bold */
      font-family: "Noto Sans";
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      margin-top: 20px;
    }

    .dropdown-container {
      display: flex;
      padding: 20px;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      align-self: stretch;
      border-radius: 12px;
      background: rgba(var(--custom_color_18));
      margin-top: 10px;
      width: 100%;

      .dropdown-row {
        display: flex;
        width: 300px;
        height: 48px;
        justify-content: space-between;
        align-items: center;
        color: rgba(var(--custom_color_17));
        font-family: "Noto Sans";
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        width: 100%;

        .area-selector {
          width: 300px;
          padding: 0px;
          border: none;

          &-toggle {
            &.dropdown-toggle {
              display: flex;
              padding: 6px 4px 8px 8px;
              flex-direction: row;
              align-items: flex-start;
              justify-content: space-between;
              gap: 8px;
              align-self: stretch;
              width: 100%;
              border: 1px solid var(--custom_color_16) !important;
              border-radius: 4px !important;
              background: var(--brand_white);
              color: var(--custom_color_21);
              font-family: 'Noto Sans';
              font-size: 14px !important;
              font-weight: 400;
              line-height: 20px !important;
              width: 300px;
              height: 32px;
              min-width: 300px;

              &:active,
              &:focus,
              &:focus-visible,
              &:focus-within,
              &:hover {
                border: 1px solid var(--custom_color_16) !important;
                border-radius: 4px !important;
                background-color: var(--brand_white) !important;
                color: var(--custom_color_19) !important;
              }

              &.btn-outline-secondary {
                &.show {
                  border: 1px solid var(--custom_color_16) !important;
                  border-radius: 4px !important;
                  background: var(--brand_white);
                  color: var(--custom_color_19);
                }
              }

              &::after {
                display: none !important;
              }
            }
          }

          &-menu {
            overflow-y: auto;
            max-height: 80px;

            &.dropdown-menu {
              width: 100%;
              padding: 8px 0px;
              background: var(--brand_white);

              &.show {
                box-shadow: 0px 10px 15px -3px rgba(var(--greyscale-custom1), 0.1),
                  0px 4px 6px -2px rgba(var(--greyscale-custom1), 0.05);
                border-radius: 4px;
              }
            }

            &-item {
              &.dropdown-item {
                width: 100%;
                font-size: 14px;
                font-weight: 500;
                line-height: 20px;
                border: none;
                background: var(--brand_white);
                color: var(--brand_black);
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;

                &.active {
                  background: var(--hGreyscale_88);
                  color: var(--brand_black);
                  font-weight: 700;
                }

                &:first-child {
                  border-top-left-radius: 12px;
                  border-top-right-radius: 12px;
                }

                &:last-child {
                  border-bottom-left-radius: 12px;
                  border-bottom-right-radius: 12px;
                }

                &.selected {
                  color: var(--brand_primary);
                }
              }

              &.dropdown-item:hover {
                background: rgba(var(--custom_color_26));
                border-top-left-radius: 0px;
                border-top-right-radius: 0px;
                border-bottom-left-radius: 0px;
                border-bottom-right-radius: 0px;
              }
            }
          }

          .area-selected {
            &-wrapper {
              display: inline-flex;
              align-items: center;
            }

            &-name {
              margin-left: 0px !important;
            }
          }
        }
      }

      .row-border {
        border-top: 1px solid rgba(var(--custom_color_26));
      }
    }

    .button-container {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      gap: 8px;
      width: 100%;
      margin-top: 24px;
    }

    .css-90a3vc-MuiButtonBase-root-MuiRadio-root {
      padding: 0px;
    }

    .css-mkl144 {
      padding: 0px;
    }

    .css-mkl144.Mui-checked {
      padding: 0px;
      color: var(--primary_40) !important;
    }

    .MuiFormGroup-root .MuiRadio-root {
      padding: 0px;

      &.Mui-checked {
        color: var(--primary_40);
      }
    }

    .device-setup-modal-subTitle {
      color: var(--brand_black);
      font-family: "Noto Sans";
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }

    .discription {
      color: var(--C-2-61, rgba(0, 0, 0, 0.61));
      /* 14 regular */
      font-family: "Noto Sans";
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }

}


.discovered-device-filter-modal {
  .modal-dialog.modal-md.modal-dialog-centered {
    display: flex;
    justify-content: center;
  }

  .modal-dialog.modal-md.modal-dialog-centered .modal-content {
    padding: 24px !important;
  }

  .modal-dialog .modal-content {
    width: 485px;
    min-width: 485px;
    padding: 24px !important;

    .modal-body {
      padding-bottom: 0;

      .device-filter-modal-wrapper {
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        justify-content: center;
      }

      .filter-checkbox input[type='checkbox'] {
        height: 14px;
        width: 14px;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 2px;
        border: 1.5px solid var(--greyscale_80);
        background: var(--brand_white);
        appearance: none;
        cursor: pointer;
        position: relative;
      }

      .filter-checkbox input[type='checkbox']:disabled {
        height: 14px;
        width: 14px;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 2px;
        border: 1.5px solid var(--greyscale-40);
        background: var(--greyscale_48);
        appearance: none;
        cursor: auto;
      }

      .filter-checkbox input[type='checkbox']:checked::after {
        position: absolute;
        width: 14px;
        height: 14px;
        color: var(--brand_white);
        content: '\2713';
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 600;
        border: none;
        border-radius: 2px;
        background: var(--primary_40);
        cursor: pointer;
      }

      .filter-table-structure {
        display: flex;
        align-items: flex-start;
        gap: 13px;

        .filter-column {
          display: flex;
          width: 212px;
          flex-direction: column;
          align-items: flex-start;
          gap: 15px;

          .hide {
            visibility: hidden;
          }

          .filter-container {
            display: flex;
            width: 212px;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
          }

          .filter-row-container {
            display: flex;
            height: 32px;
            padding: 0px 8px;
            align-items: center;
            gap: 8px;
            align-self: stretch;
            color: rgba(var(--custom_color_17));
            font-family: "Noto Sans";
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            width: max-content;
          }

          .filter-header {
            color: var(--greyscale_00);
            font-family: "Noto Sans";
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
          }
        }

        .filter-column-border {
          border-right: 1px solid rgba(var(--custom_color_27));
        }
      }

      .filter-footer {
        padding: 20px 8px 0px 8px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 100%;

        .left-section {
          display: flex;
          flex-direction: row;
          align-items: flex-start;
          color: rgba(var(--custom_color_28));
          text-align: center;
          font-family: "Noto Sans";
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          align-items: center;
          gap: 4px;

          .rotate-reload {
            transform: rotateX(180deg);
          }
        }

        .right-section {
          display: flex;
          flex-direction: row;
          align-items: flex-end;
          gap: 8px;
        }
      }
    }
  }
}

.discovered-device-date-modal {
  .modal-dialog.modal-lg.modal-dialog-centered {
    display: flex;
    justify-content: center;
  }

  .modal-dialog .modal-content {
    width: 316px;
    min-width: 316px;
    padding: 16px 20px 20px 20px !important;

    .modal-body {
      padding-bottom: 0;
      max-height: 100%;

      .date-time-picker {
        &.dark {
          .custom-calendar.rmdp-wrapper {
            .rmdp-calendar {
              .rmdp-header {
                margin-bottom: 6px !important;
                margin-top: 0px !important;

                &.disabled {
                  &:hover {
                    background-color: var(--brand_primary) !important;
                  }
                }
              }
            }
          }
        }

        .date-time-picker-main-range {
          .timeline-calendar.rmdp-wrapper {
            .rmdp-calendar .rmdp-header {
              .rmdp-header-values {

                >span:first-child,
                span:last-child {
                  background-color: var(--brand_white) !important;
                  color: var(--brand_black) !important;
                  padding: 2px 0px 3px !important;
                }
              }
            }
          }
        }

        .rmdp-calendar-range .rmdp-header {
          .rmdp-header-values {

            >span:first-child,
            span:last-child {
              background-color: var(--brand_white) !important;
              color: var(--brand_black) !important;
              padding: 2px 10px 3px !important;
            }
          }
        }

        .date-time-picker-main {
          .timeline-calendar.rmdp-wrapper {
            .rmdp-calendar .rmdp-header {
              .rmdp-header-values {

                >span:first-child,
                span:last-child {
                  background-color: var(--primary_96) !important;
                  color: var(--primary_32) !important;
                  padding: 2px 10px 3px !important;
                  border-radius: 4px !important;
                }
              }
            }
          }
        }

        .select-time-label {
          color: var(--greyscale_08);
          font-size: 16px;
          font-style: normal;
          font-weight: 600;
          line-height: 20px;
          /* 125% */
          margin-top: 24px;
          margin-bottom: 8px;
        }

        .custom-calendar.rmdp-wrapper {
          width: 100%;
          border: none;

          .rmdp-calendar {
            width: 100%;
            padding: 0px;

            .rmdp-day-picker,
            .rmdp-month-picker,
            .rmdp-year-picker {
              width: 100%;
              padding: 0px;

              >div {
                width: 100%;
              }

              .rmdp-week,
              .rmdp-ym {
                width: 100%;

                .rmdp-week-day {
                  color: var(--greyscale_40);
                  text-align: center;
                  font-size: 12px;
                  font-style: normal;
                  font-weight: 600;
                  line-height: 16px;
                  /* 133.333% */
                  text-transform: uppercase;
                  display: flex;
                  width: 42px;
                  height: 18px;
                  padding: 0px 5px 2px 6px;
                  justify-content: center;
                  align-items: center;
                }

                .rmdp-day {
                  width: 32px;
                  height: 32px;
                  padding: 5px 0px 7px 0px;

                  &:not(.rmdp-disabled):not(.rmdp-day-hidden) span:hover {
                    background-color: var(--primary_48);
                  }
                }

                .rmdp-selected {
                  >span {
                    &:not(.highlight) {
                      background-color: var(--primary_40);
                    }
                  }
                }
              }
            }

            .rmdp-header {
              width: 100%;
              height: 28px;
              color: var(--brand_black);
              font-size: 14px;
              font-style: normal;
              font-weight: 600;
              line-height: 20px;
              margin-top: 0px;
              display: flex;
              align-items: center;
              justify-content: center;
              margin-top: 0px;

              /* 142.857% */
              .rmdp-header-values {
                margin-left: 0px;

                >span {
                  padding: 0px;
                }

                >span:first-child,
                span:last-child {
                  background-color: var(--primary_96);
                  color: var(--primary_32);
                  padding: 2px 10px 3px;
                  border-radius: 4px;
                }

                >span:last-child {
                  margin-left: 5px;
                }
              }

              .rmdp-arrow-container {
                .rmdp-arrow {
                  width: 9.971px;
                  height: 11.324px;
                  flex-shrink: 0;
                  border-color: var(--brand_primary);
                }

                &:hover {
                  box-shadow: none;
                  background-color: var(--brand_primary);

                  .rmdp-arrow {
                    border-color: var(--brand_white);
                  }
                }
              }

              .disabled {
                .rmdp-arrow {
                  border-color: var(--primary_80);
                }

                &:hover {
                  border-color: var(--brand_white) !important;
                  background-color: var(--brand_white) !important;
                }
              }
            }
          }
        }

        .custom-calendar-range.rmdp-wrapper {
          width: 100%;
          border: none;

          .rmdp-calendar {
            width: 100%;
            padding: 0px;

            .rmdp-day-picker,
            .rmdp-month-picker,
            .rmdp-year-picker {
              width: 100%;
              padding: 0px;

              >div {
                width: 100%;
              }

              .rmdp-week {
                margin-top: 0px;
                margin-bottom: 4px;
              }

              .rmdp-week:first-child {
                rmdp-week-day span {
                  color: var(--custom_color_29);
                }

                rmdp-day span {
                  color: var(--custom_color_29);
                }
              }

              .rmdp-week,
              .rmdp-ym {
                width: 100%;

                .rmdp-week-day {
                  display: flex;
                  width: 40px;
                  height: 20px;
                  flex-direction: column;
                  justify-content: center;
                  color: var(--custom_color_30);
                  text-align: center;
                  font-family: "Noto Sans";
                  font-size: 12px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: normal;
                  margin-bottom: 4px;
                }

                .rmdp-day {
                  display: flex;
                  width: 40px;
                  height: 28px;
                  flex-direction: column;
                  justify-content: center;
                  color: var(--greyscale_00);
                  text-align: center;
                  font-family: "Noto Sans";
                  font-size: 14px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: normal;

                  &:not(.rmdp-disabled):not(.rmdp-day-hidden) span:hover {
                    background-color: var(--primary_48);
                  }
                }

                .rmdp-day span {
                  font-weight: 600;
                  bottom: 0px;
                  right: 0px;
                  left: 0px;
                  top: 0px;
                  border-radius: 8px;
                }

                .rmdp-selected {
                  >span {
                    &:not(.highlight) {
                      background-color: var(--primary_40);
                    }
                  }
                }

                .rmdp-day.rmdp-today span {
                  display: flex;
                  width: 100%;
                  height: 28px;
                  flex-direction: column;
                  justify-content: center;
                  color: var(--greyscale_00);
                  border: 1.5px solid var(--greyscale_00);
                  border-radius: 8px;
                  text-align: center;
                  font-family: "Noto Sans";
                  font-size: 14px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: normal;
                  background-color: var(--brand_white);
                  margin-left: 6px;
                }

              }
            }

            .rmdp-year-picker,
            .rmdp-month-picker {
              left : 0;
              .rmdp-ym {
                .rmdp-today span {
                  margin-left: 0px !important;
                }
              }
            }

            .rmdp-header {
              width: 100%;
              color: var(--brand_black);
              font-size: 14px;
              font-style: normal;
              font-weight: 600;
              line-height: 20px;
              margin-bottom: 16px;

              /* 142.857% */
              .rmdp-header-values {
                margin-left: 6px;
                display: flex;

                >span {
                  padding: 0px;
                }

                >span:first-child,
                span:last-child {
                  // background-color: var(--primary_96);
                  // color: var(--primary_32);
                  padding: 2px 6px 3px;
                  border-radius: 4px;
                }

                >span:last-child {
                  // margin-left: 5px;
                }
              }

              .rmdp-arrow-container {
                .rmdp-arrow {
                  width: 9.971px;
                  height: 11.324px;
                  flex-shrink: 0;
                  border-color: var(--brand_primary);
                }

                &:hover {
                  box-shadow: none;
                  background-color: var(--brand_primary);

                  .rmdp-arrow {
                    border-color: var(--brand_white);
                  }
                }
              }

              .disabled {
                .rmdp-arrow {
                  border-color: var(--primary_80);
                }

                &:hover {
                  border-color: var(--brand_white) !important;
                  background-color: var(--brand_white) !important;
                }
              }
            }

            .rmdp-range {
              background: rgba(0, 0, 0, 0.08);
              box-shadow: none;
            }

            .rmdp-range.start .sd {
              background-color: var(--primary_40) !important;
              color: var(--brand_white) !important;
              border-bottom-right-radius: 8px;
              border-top-right-radius: 8px;
              border-bottom-left-radius: 8px;
              border-top-left-radius: 8px;

            }

            .rmdp-range.end .sd {
              background-color: var(--primary_40) !important;
              color: var(--brand_white) !important;
              border-bottom-right-radius: 8px;
              border-top-right-radius: 8px;
              border-bottom-left-radius: 8px;
              border-top-left-radius: 8px;


            }

            .rmdp-range.start {
              border-bottom-left-radius: 8px;
              border-top-left-radius: 8px;
            }

            .rmdp-range.end {
              border-bottom-right-radius: 8px;
              border-top-right-radius: 8px;
            }
          }
        }

        .custom-calendar-range-same.rmdp-wrapper { 
            .rmdp-range {
              background: initial !important;
            }
        }

        .time-container {
          display: flex;
          width: 100%;
          height: 44px;
          padding: 12px 16px;
          flex-direction: row;
          align-items: center;
          justify-content: space-between;
          border-radius: 12px;
          border: 1.5px solid var(--greyscale_88);
          background: var(--brand_white);
          margin-bottom: 1px;
          cursor: pointer;
        }

        .custom-timepicker.rmdp-wrapper {
          margin-left: -16px;
          margin-top: -18px;
          width: 169.5%;
        }

        .custom-timepicker.rmdp-shadow {
          box-shadow: none;
        }

        .custom-timepicker.ep-arrow {
          visibility: hidden !important;
        }

        .rmdp-container {
          width: 100%;
          align-items: center;
        }

        .custom-timepicker.rmdp-wrapper,
        .rmdp-input {
          border: none;
          box-shadow: none;
          color: var(--greyscale_08);
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 20px;
          /* 142.857% */
          // width: 169.5%;
          height: 98%;
          caret-color: transparent;
          cursor: pointer;
        }

        .rmdp-time-picker.bottom {
          div {
            input {
              caret-color: auto;
            }
          }
        }

        .custom-timepicker.rmdp-wrapper {
          // border: 0.5px solid var(--greyscale_88);
          // border-radius: 12px;
        }
      }

      .button-container {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        margin-top: 36px;

        .today {
          display: flex;
          width: 63px;
          height: 32px;
          padding: 0px 12px;
          justify-content: center;
          align-items: center;
          gap: 4px;
          flex-shrink: 0;
          color: var(--custom_color_30);
          text-align: center;
          font-family: "Noto Sans";
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }
      }
    }
  }
}