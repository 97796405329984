.date-range-picker-container {
  position: relative;

  .range-btn-container {
    position: absolute;
    bottom: 20px;
    right: 20px;

    .ok-btn {
      color: var(--brand_white);
      border: none;
      cursor: pointer;
      font-family: "Noto Sans";
      font-size: 0.875rem;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      height: 32px;
      min-width: 80px;
      padding: 0px 12px;
      gap: 4px;
      border-radius: 4px;
      background: var(--brand_primary);
    }
  }
}

.rdrMonthAndYearPickers {
  order: 1;
  margin-right: 20px;
}

.rdrNextPrevButton {
  order: 2;
  background: transparent;
}

.rdrPprevButton {
  background-image: url('../../assets/images/ArrowUp.svg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  width:16px;
}

.rdrPprevButton:hover{
  background-image: url('../../assets/images/ArrowUp.svg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  width:16px;
  background-color: #ffffff;
}

.rdrPprevButton i {
  display: none;
}

.rdrNextButton {
  background-image: url('../../assets/images/ArrowDown.svg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  width:16px;
  margin-right: 40px;
}

.rdrNextButton:hover{
  background-image: url('../../assets/images/ArrowDown.svg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  width:16px;
  margin-right: 40px;
  background-color: #ffffff;

}

.rdrNextButton i {
  display: none;
}

.rdrStaticRanges {
  padding: 12px 0px 46px 0px;
  gap: 2px;
}

.rdrMonthAndYearWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.rdrDateDisplayWrapper {
  display: none;
}

.rdrDefinedRangesWrapper {
  background: var(--custom_color_41);
  border-top-left-radius: 12px;
  border-bottom-left-radius: 12px;

  button {
    background: var(--custom_color_41);
  }
}

.rdrCalendarWrapper {
  border-top-right-radius: 12px;
  border-bottom-right-radius: 12px;
}

.rdrStaticRange {
  border-bottom: none;

  &.rdrStaticRangeSelected {
    border-left: 2px solid var(--brand_primary);
    background: rgba(var(--custom_color_26));

    .rdrStaticRangeLabel {
      font-style: normal;
      font-weight: 400;
      color: var(--brand_black) !important;
    }
  }
}

.rdrStaticRangeLabel {
  padding: 7px 20px;
  font-size: 0.875rem;
  color: rgba(var(--custom_color_17));
}


.rdrMonthAndYearPickers {
  select {
    color: rgba(var(--custom_color_17));
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 600;
    line-height: 26px;
    padding: 10px 25px 10px 10px;
    color: #3e484f;
    background-position: right 8px center;
    background-image: url('../../assets/images/ArroeSolidDown.svg');
    background-repeat: no-repeat;
  }
}

.rdrDays {
  button {
    margin-top: 8px;

    .rdrStartEdge,
    .rdrInRange,
    .rdrEndEdge {
      height: 28px;
    }
  }

  .rdrDayNumber {
    font-size: 0.875rem;
    bottom: 2px;

    .date-red {
      color: var(--custom_color_29);
    }
  }

  .rdrDayPassive {
    .rdrDayNumber {
      .date-red {
        color: #d5dce0;
      }
    }
  }

  .rdrDayStartPreview,
  .rdrDayInPreview,
  .rdrDayEndPreview {
    height: 32px;
  }
}

.rdrWeekDays {
  font-size: 0.75rem;
}

.rdrWeekDay:first-child {
  color: var(--custom_color_29);
}

.rdrMonth {
  padding: 1rem;
}

.rdrMonthName {
  display: none;
}

.rdrDayEndOfMonth .rdrInRange,
.rdrDayEndOfMonth .rdrStartEdge,
.rdrDayEndOfWeek .rdrInRange,
.rdrDayEndOfWeek .rdrStartEdge {
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  right: 3px;
}

.rdrEndEdge {
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  right: 3px;
}

.rdrStartEdge {
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  left: 3px;
}

.rdrDayStartOfMonth .rdrInRange,
.rdrDayStartOfMonth .rdrEndEdge,
.rdrDayStartOfWeek .rdrInRange,
.rdrDayStartOfWeek .rdrEndEdge {
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  left: 3px;
}

.rdrDayStartPreview {
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
}

.rdrDayEndPreview {
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}

.rdrDayStartOfMonth .rdrDayInPreview,
.rdrDayStartOfMonth .rdrDayEndPreview,
.rdrDayStartOfWeek .rdrDayInPreview,
.rdrDayStartOfWeek .rdrDayEndPreview {
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
}

.rdrDayEndOfMonth .rdrDayInPreview,
.rdrDayEndOfMonth .rdrDayStartPreview,
.rdrDayEndOfWeek .rdrDayInPreview,
.rdrDayEndOfWeek .rdrDayStartPreview {
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}

// .rdrInRange {
//   background-color: rgba(var(--custom_color_18));
// }
