.dropdown-container {
  width: 320px;
  position: relative;

  .dropdown {
    display: flex;
    gap: 8px;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 32px;
    border: 1px solid var(--input-outline-default);
    border-radius: 4px;
    padding: 6px 8px;

    &-text-field {
      text-align: start;
      width: 100%;
      font-size: 14px;
      line-height: 20px;
      overflow: hidden;
      text-overflow: ellipsis;
      color: var(--text-normal-secondary);
    }

    &:hover {
      border: 1px solid var(--input-outline-hover);
      background-color: var(--input-foreground-hover);
      color: var(--text-normal-secondary);
    }

    &:active {
      border: 1px solid var(--input-outline-press);
      background-color: var(--input-foreground-press);
      color: var(--text-normal-secondary);
    }
  }

  .opened {
    border: 1px solid var(--input-outline-active);
  }

  .dropdown-items {
    position: absolute;
    top: 36px;
    width: 100%;
    border: 1px solid var(--border-default);
    border-radius: 4px;
    background-color: var(--background-surface-popup);
    z-index: 100;
    overflow-y: auto;
    max-height: 240px;
    padding: 4px;
    box-shadow: 0 4px 8px 0 var(--shadow-2);

    .dropdown-item {
      display: flex;
      align-content: center;
      width: 100%;
      height: 32px;
      padding: 6px 8px;
      font-size: 14px;
      line-height: 20px;
      overflow: hidden;
      text-overflow: ellipsis;
      border-radius: 4px;

      &:hover {
        background: var(--background-interaction-hover);
      }
      &:active {
        background: var(--background-interaction-press);
      }
    }

    .selected {
      background-color: var(--background-interaction-select-orange) !important;
    }

    &::-webkit-scrollbar {
      width: 6px;
    }

    &::-webkit-scrollbar-thumb {
      background: var(--component-scroll-default);
      border-radius: 20px;
      width: 6px;
    }
  }
}
