.paginated-license-page {
  width: 100%;
  justify-content: flex-start;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: calc(100vh - 104px);
}

.paginated-licenses {
  margin-top: 32px;
  margin-bottom: 32px;
  text-align: left;
  display: flex;
  flex-direction: column;
  font-family: Noto Sans;
  width: 100%;

  @media screen and (max-width: 1560px) {
    padding: 0px 160px;
  }

  @media screen and (max-width: 1920px) and (min-width: 1561px) {
    padding: 0px 160px;
  }

  @media screen and (min-width: 1920px) {
    padding: 0px 178px;
  }

  .licenses-header {
    display: flex;
    flex-direction: column;
    gap: 4px;
    width: 100%;
    text-align: left;

    .licenses-title {
      font-size: 24px;
      font-weight: 600;
      line-height: 32.69px;
      color: var(--greyscale_00);
    }
  }

  .licenses-filters {
    display: flex;
    height: max-content;
    justify-content: space-between;
    margin-top: 7px;
    flex-wrap: wrap;
    gap: 4px;

    .reload-filter-edit {
      width: max-content;
      min-width: 100px;
      height: 32px;
      padding: 7px 16px 8px 16px;
      gap: 8px;
      border-radius: 4px;
      border: 1px solid var(--custom_color_16);
      align-items: center;
      display: flex;
      justify-content: center;
      color: rgba(var(--custom_color_17));
      text-align: center;
      font-family: "Noto Sans";
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      white-space: nowrap;

      &:hover {
        background-color: rgba(var(--custom_color_18));
      }

      &:active {
        border: 1px solid rgba(var(--custom_color_19));
        background: rgba(var(--custom_color_26));
      }
    }
    .disabled {
      border: 1px solid rgba(var(--custom_color_19));
      color: rgba(var(--custom_color_20));
      pointer-events: none;
    }
    .remove-device {
      width: 120px !important;
    }

    .left-side {
      display: flex;
      height: max-content;
      gap: 8px;
      align-items: center;
      font-size: 14px;
      line-height: 20px;
      text-align: left;
      flex-wrap: wrap;

      .count-container {
        font-weight: 400;
        display: flex;
        gap: 4px;

        .display-bold {
          font-weight: 700;
        }

        width: max-content;
      }

      .area-selector {
        // max-width: 320px;
        // min-width: 270px;
        width: 320px;

        &-toggle {
          &.dropdown-toggle {
            display: flex;
            padding: 6px 8px;
            flex-direction: row;
            align-items: flex-start;
            justify-content: space-between;
            gap: 8px;
            align-self: stretch;
            width: 100%;
            border: 1px solid var(--custom_color_16) !important;
            border-radius: 4px !important;
            background: initial;
            color: var(--custom_color_21);
            font-family: "Noto Sans";
            font-size: 14px !important;
            font-weight: 400;
            line-height: 20px !important;
            max-width: 320px;
            min-width: 270px;
            height: 32px;

            &:active,
            &:focus,
            &:focus-visible,
            &:focus-within,
            &:hover {
              border: 1px solid var(--custom_color_16) !important;
              border-radius: 4px !important;
              background: initial !important;
              color: var(--custom_color_19) !important;
            }

            &.btn-outline-secondary {
              &.show {
                border: 1px solid var(--custom_color_16) !important;
                border-radius: 4px !important;
                background: initial;
                color: var(--custom_color_19);
              }
            }

            &::after {
              display: none !important;
            }
          }
        }

        &-menu {
          overflow-y: auto;
          max-height: 300px;

          &.dropdown-menu {
            width: 100%;
            padding: 8px 0px;
            background: var(--brand_white);

            &.show {
              box-shadow: 0px 10px 15px -3px rgba(var(--greyscale-custom1), 0.1),
                0px 4px 6px -2px rgba(var(--greyscale-custom1), 0.05);
              border-radius: 4px;
            }
          }

          &-item {
            &.dropdown-item {
              width: 100%;
              font-size: 14px;
              font-weight: 500;
              line-height: 20px;
              border: none;
              background: initial;
              color: var(--brand_black);
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;

              &.active {
                background: var(--hGreyscale_88);
                color: var(--brand_black);
                font-weight: 700;
              }

              &:first-child {
                border-top-left-radius: 12px;
                border-top-right-radius: 12px;
              }

              &:last-child {
                border-bottom-left-radius: 12px;
                border-bottom-right-radius: 12px;
              }

              &.selected {
                color: var(--brand_primary);
              }
            }

            &.dropdown-item:hover {
              background: rgba(var(--custom_color_26));
              border-top-left-radius: 0px;
              border-top-right-radius: 0px;
              border-bottom-left-radius: 0px;
              border-bottom-right-radius: 0px;
            }
          }
        }

        .area-selected {
          &-wrapper {
            display: inline-flex;
            align-items: center;
          }

          &-name {
            margin-left: 0px !important;
          }
        }
      }

      .add-device-selector {
        width: 120px;

        &-toggle {
          &.dropdown-toggle {
            display: flex;
            padding: 0px 12px;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            gap: 8px;
            align-self: stretch;
            width: 100%;
            border: 1px solid var(--custom_color_16) !important;
            border-radius: 4px !important;
            background: initial;
            color: var(--custom_color_21);
            font-family: "Noto Sans";
            font-size: 14px !important;
            font-weight: 400;
            line-height: 20px !important;
            height: 32px;

            &:active,
            &:focus,
            &:focus-visible,
            &:focus-within,
            &:hover {
              border: 1px solid var(--custom_color_16) !important;
              border-radius: 4px !important;
              background: initial !important;
              color: var(--custom_color_19) !important;
            }

            &.btn-outline-secondary {
              &.show {
                border: 1px solid var(--custom_color_16) !important;
                border-radius: 4px !important;
                background: initial;
                color: var(--custom_color_19);
              }
            }

            &::after {
              display: none !important;
            }
          }
        }

        &-menu {
          overflow-y: auto;
          width: 200px;
          max-height: 240px;
          padding: 4px;

          &.dropdown-menu {
            width: 100%;
            padding: 8px 0px;
            background: var(--brand_white);

            &.show {
              box-shadow: 0px 10px 15px -3px rgba(var(--greyscale-custom1), 0.1),
                0px 4px 6px -2px rgba(var(--greyscale-custom1), 0.05);
              border-radius: 4px;
            }
          }

          &-item {
            &.dropdown-item {
              width: 100%;
              display: flex;
              height: 32px;
              padding: 0px 8px;
              align-items: center;
              font-size: 14px;
              font-weight: 500;
              line-height: 20px;
              border: none;
              background: initial;
              color: var(--brand_black);
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;

              &.active {
                background: var(--hGreyscale_88);
                color: var(--brand_black);
                font-weight: 700;
              }

              &.disabled {
                opacity: 0.5;
              }

              &:first-child {
                border-top-left-radius: 0px;
                border-top-right-radius: 0px;
              }

              &:last-child {
                border-bottom-left-radius: 0px;
                border-bottom-right-radius: 0px;
              }
            }

            &.dropdown-item:hover {
              background: rgba(var(--custom_color_26));
              border-top-left-radius: 0px;
              border-top-right-radius: 0px;
              border-bottom-left-radius: 0px;
              border-bottom-right-radius: 0px;
            }
          }
        }

        .add-device-selector {
          &-wrapper {
            display: inline-flex;
            align-items: center;
            border-radius: 4px;
          }

          &-name {
            margin-left: 0px !important;
          }
        }
      }
    }

    .right-side {
      display: flex;
      height: max-content;
      gap: 8px;
      align-items: center;
      font-size: 14px;
      line-height: 20px;
      text-align: left;
      margin-left: 8px;
      flex-wrap: wrap;

      .search-container {
        display: flex;
        text-align: center;
        align-items: center;
        width: 340px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        align-self: stretch;
        border-radius: 4px;
        border: 1px solid var(--custom_color_16);
        background: initial;
        position: relative;

        & .search-icon {
          z-index: 9;
          margin: 4px 8px 4px 4px;
          position: absolute;
          left: 0;
        }

        & .search-input {
          padding: 6px 8px 6px 32px;
          color: var(--greyscale_00);
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 20px;
          background: initial;
          width: 310px;
          height: 30px;
          border-radius: none;
          border: none;

          &:hover {
            background: initial !important;
            color: var(--greyscale_40) !important;
            border-color: var(--dark_orange);
          }

          &:focus {
            color: var(--bs-body-color);
            background-color: var(--bs-body-bg);
            border-color: var(--greyscale_buttons_2); // ToDo: Need to Fix
            outline: 0;
          }
        }

        & .search-close {
          position: absolute;
          cursor: pointer;
          right: 0;
          margin: 8px;
        }
      }
    }
  }
}

.loader-style {
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  color: var(--greyscale_56);
  top: 55%;
}

.total-license-info {
  display: flex;
  flex-direction: row;
  padding: 20px 32px;
  border: 1px solid rgba(var(--custom_color_19));
  border-radius: 8px;
  gap: 20px;
  cursor: pointer;

  &:hover {
    background-color: rgba(var(--custom_color_18));
  }

  &:active {
    background-color: rgba(var(--custom_color_26));
  }

  .info-section {
    // display: flex;
    flex-direction: row;
    flex: 1 0 0;
    justify-content: space-between;
    align-items: center;
    padding-right: 24px;
    border-right: 1px solid rgba(var(--custom_color_26));

    .section-sort-info {
      display: flex;
      justify-content: space-between;
    }

    .detailed-info-section-list {
      display: none;
      flex-direction: column;

      &.show {
        display: flex;
      }

      .detailed-info-section {
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        .detailed-info-label {
          color: rgba(var(--custom_color_17));
          text-align: center;
          font-size: 0.875rem;
          font-style: normal;
          font-weight: 400;
          line-height: 20px;
        }
        .detailed-info-value {
          color: rgba(var(--custom_color_17));
          text-align: center;
          font-size: 14px;
          font-style: normal;
          font-weight: 600;
          line-height: 18px;
        }
      }
    }

    &:last-child {
      padding-right: 0;
      border-right: none;
    }

    .section-label {
      color: var(--custom_color_17);
      text-align: center;
      font-size: 0.875rem;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      align-items: center;
      display: flex;
      gap: 4px;

      svg {
        height: 22px;
        width: 22px;

        &.checked-icon {
          color: var(--custom_color_25);
        }
        &.warning-icon {
          color: var(--custom_color_34);
        }
        &.error-icon {
          color: var(--custom_color_29);
        }
      }
    }

    .section-value {
      color: var(--custom_color_17);
      text-align: center;
      font-size: 1.25rem;
      font-style: normal;
      font-weight: 700;
      line-height: 28px;
    }
  }
}

thead {
  th {
    .column-saparator {
      border-right: 1px solid rgba(var(--custom_color_27));
    }
    &:last-child {
      .column-saparator {
        border-right: none;
      }
    }
  }
}

.status-field {
  display: flex;
  gap: 6px;
  align-items: center;

  .status-indicator {
    height: 8px;
    width: 8px;

    &.status-available {
      color: var(--custom_color_35);
    }

    // &.status-inactive {
    //   color: var(--custom_color_35);
    // }

    &.status-active {
      color: var(--custom_color_25);
    }

    &.status-expiring-soon {
      color: var(--custom_color_36);
    }

    &.status-expired {
      color: var(--custom_color_33);
    }
  }

  .status-value {
    color: rgba(var(--custom_color_17));
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
}

.manage-licenses {
  .license-tab{
    display: flex;
    gap: 30px;
    .nav-item {
      display: inline-flex;
      align-items: flex-start;
      gap: 10px;
      .nav-link {
        padding: 12px 0px;
        color: rgba(var(--custom_color_24));
        text-align: center;
        font-size: toRem(16px);
        font-weight: 400;
        line-height: toRem(22px);
        border: none;

        &.active {
          border: none;
          color: var(--brand_black);
          font-weight: 700;
          border-bottom: 2px solid var(--dark_orange);
          &:hover {
            border-bottom: 2px solid var(--dark_orange);
          }
        }
        &:active,
        &:hover {
          border: none;
        }
      }
    }
  }
  .page {
    &-title {
      padding-left: 0;
      font-size: 1.5rem;
      font-weight: 700;
      margin-bottom: 4px;
    }

    &-header {
      .purchase-button {
        height: 44px;
        padding: 8px 16px;

        &-label {
          font-size: 0.875rem;
        }
      }

      .toast-wrapper {
        text-align: left;
      }
    }
  }
  .table {
    width: 100%;
    min-width: 1558px !important;
    margin-bottom: 4px;
  }
  .table tr td:first-child {
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
    padding-left: 12px !important;
  }

  .table tr td:last-child {
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    padding-right: 20px !important;
  }

  .table tr th:first-child {
    border-top-left-radius: 8px;
    border-bottom-left-radius: 0px;
    padding-left: 12px !important;
  }

  .table tr th:last-child {
    border-top-right-radius: 8px;
    border-bottom-right-radius: 0px;
    padding-right: 20px !important; 
  }

  .table-responsive {
    overflow-x: scroll;
    justify-content: flex-start;
    overflow-y: hidden;

    .grip-container {
      min-width: 1558px !important;
    }
    
    .grip-drag {
        background-color: var(--brand_primary);
        opacity: 1;
        width: 1px;
        border-color: var(--brand_primary);
    }

    .grip-handle {
      margin-left: -2px;
    }
    
    .licenses-table {
      thead {
        border-spacing: 0;

        th {
          border: 0;
          color: var(--greyscale_56);
          max-width: 120px;

          .header-sort {
            cursor: pointer;
            display: inline-flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;

            .column-name {
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              width: 100px;
            }

            .sort-icon {
              margin-right: 20px;
              font-size: 1.5rem;
            }
          }
        }
      }

      tbody {
        tr {
          &:hover {
            background-color: var(--greyscale_88);
          }
          td {
            max-width: 120px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            border-left: 0;
            border-right: 0;

            .date-field {
              display: inline-flex;
              align-items: center;

              .calendar-icon {
                margin-right: 0.406rem;
              }

              .exclamation-icon {
                margin-right: 0.406rem;
                color: var(--warning_48);
              }

              .warning-date {
                color: var(--warning_48);
              }
            }

            .status {
              &-field {
                display: inline-flex;
                align-items: center;
              }

              &-indicator {
                margin-right: 0.375rem;
                height: 0.625rem;
                width: 0.625rem;
              }

              &-active,
              &-available {
                color: var(--success_48);
              }

              &-inactive {
                color: var(--greyscale_40);
              }

              &-expiring-soon {
                color: var(--warning_64);
              }

              &-expired {
                color: var(--error_48);
              }
            }
          }
        }
      }
    }
    @media (max-width: 1400px) {
      overflow-x: auto;

      // .licenses-table {
      //     tbody tr td {
      //         max-width: 100%;
      //     }
      //     thead tr th {
      //         max-width: 100%;
      //     }
      // }
    }
    .mobile {
      cursor: pointer;
    }
  }
}

.enterTextManullyStyle {
  pointer-events: none !important;
  padding-top: 2%;
  // padding-left: 6.4% !important;
  font-family: Noto Sans;
  font-size: 12px;
  font-weight: 100;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left !important;
  color: var(--greyscale_56);
  .row {
    margin-top: 19px;
    margin-left: 21px;
  }
  margin-left: 8px;
  width: 176px;
}

.enterTextManullyHighlightedStyle {
  cursor: pointer !important;
  padding-top: 4%;
  // padding-left: 6.4% !important;
  font-family: Noto Sans;
  font-size: 12px;
  font-weight: 100;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left !important;
  color: var(--dark_orange);
  .row {
    margin-top: 19px;
    margin-left: 21px;
  }
  margin-left: 8px;
  width: 176px;
}

.didNotGetCodeTextStyle {
  font-family: Noto Sans;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  color: var(--greyscale_56);
  padding-left: 3px;
  margin-top: -12px;
}

.clickToResendTextStyle {
  cursor: pointer !important;
  font-family: Noto Sans;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  color: var(--greyscale_56);
  text-decoration: underline;
  padding-left: 6px;
  margin-top: -12px;
}

.clickToResendTextStyle1 {
  cursor: pointer !important;
  font-family: Noto Sans;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  color: var(--greyscale_56);
  // text-decoration: underline;
  padding-left: 6px;
  margin-top: -12px;
}

.large-text-box {
  height: 64px !important;
  width: 64px !important;
  text-align: center !important;
  font-family: Noto Sans !important;
  font-size: 24px !important;
  font-weight: 500 !important;
  line-height: 32px !important;
  letter-spacing: 0em !important;
}

.license-name {
  .col-md-2 {
    width: 14% !important;
  }
}

.cofirmationCodeMessageStyle {
  font-family: Noto Sans;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
}

.confirmStyle {
  font-family: Noto Sans;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
}

.LicenseSuccessRoundStyle {
  margin-left: -15px;
}

.LicenseSuccessMarkStyle {
  margin-left: -64px;
}

.LicenseSuccessMessageStyle {
  font-family: Noto Sans;
  font-size: 20px;
  font-weight: 600;
  line-height: 28px;
  letter-spacing: 0em;
  text-align: center;
  margin-top: 21px;
}

.LicenseTextFieldStyle {
  margin-top: 12px;
}

.licenseRemoveIconStyle {
  margin-top: 12px;
}

.removableLicenseTextFieldStyle {
  padding-right: 0% !important;
}
@media (max-width: 570px) {
  img.licenseRemoveIconStyle {
    display: block;
    float: none;
    margin: 0px 0px 0px auto;
  }
  .erroMessageIconStyle {
    margin-left: 5px !important;
  }
  .removableLicenseTextFieldStyle {
    padding-right: 4% !important;
  }
}

.mark-icon-class {
  fill: none !important;
  stroke: none !important;
}

.erroMessage {
  font-family: Noto Sans;
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
  margin-left: 6px;
}

.erroMessageIconStyle {
  margin-left: 21px;
}

.marginBottomKey {
  margin-top: -14px !important;
}

.title-container {
  position: sticky;
  top: 0;
  width: 100%;
  z-index: 1000;
  background-color: var(--brand_white);
  margin-top: 9px;
}

.modal-dialog .modal-content .modal-body .modal-form .form-control {
  &:hover,
  &:focus {
    border-color: var(--light_orange) !important;
  }
}

.site-modal-close {
  cursor: pointer !important;
  color: var(--dark_gray);
  padding: 1.5px;
  font-weight: 600;
  stroke-width: 1;
}
.site-modal-close:hover {
  color: var(--button-color-1); /* Hover color */
}
.site-modal-close:active {
  color: var(--dark_orange); /* Color on press */
}

.errorStyle1 {
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  color: var(--error_64);
  padding-left: 3px;
  margin-top: -15px;
  padding-bottom: 12px;
}

.addLicenseBtnStyle {
  outline: none !important;
}

.plusIconClass {
  margin-right: 5px;
}

.license-filter-modal {
  .modal-dialog.modal-lg.modal-dialog-centered {
    display: flex;
    justify-content: center;
  }

  .modal-dialog .modal-content {
    width: 1007px;
    min-width: 1007px;
    padding: 24px !important;

    .modal-body {
      padding-bottom: 0;

      .license-filter-modal-wrapper {
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        justify-content: center;
      }

      .filter-checkbox input[type="checkbox"] {
        height: 14px;
        width: 14px;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 2px;
        border: 1.5px solid var(--greyscale_80);
        background: var(--brand_white);
        appearance: none;
        cursor: pointer;
        position: relative;
      }

      .filter-checkbox input[type="checkbox"]:disabled {
        height: 14px;
        width: 14px;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 2px;
        border: 1.5px solid var(--greyscale-40);
        background: var(--greyscale_48);
        appearance: none;
        cursor: auto;
      }

      .filter-checkbox input[type="checkbox"]:checked::after {
        position: absolute;
        width: 14px;
        height: 14px;
        color: var(--brand_white);
        content: "\2713";
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 600;
        border: none;
        border-radius: 2px;
        background: var(--primary_40);
        cursor: pointer;
      }

      .filter-checkbox input[type="radio"] {
        display: block;
        height: 16px;
        width: 16px;
      }
      .filter-checkbox .custom-radio-icon {
        display: none;
      }
      .filter-checkbox input[type="radio"]:checked {
        display: none;
      }
      .filter-checkbox input[type="radio"]:checked ~ .custom-radio-icon {
        display: block;
      }

      .filter-table-structure {
        display: flex;
        align-items: flex-start;
        gap: 13px;
        overflow-y:auto;

        .filter-column {
          display: flex;
          width: 230px;
          height: 197px;
          flex-direction: column;
          align-items: flex-start;
          gap: 15px;

          .hide {
            visibility: hidden;
          }

          .filter-container {
            display: flex;
            width: 230px;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
          }

          .filter-row-container {
            display: flex;
            height: auto;
            padding: 0px 8px 12px 8px;
            align-items: flex-start;
            gap: 8px;
            align-self: stretch;
            color: rgba(var(--custom_color_17));
            font-family: "Noto Sans";
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px;
            width: 100%;
            word-break: break-word;
            overflow-wrap: break-word;
            white-space: normal;
            overflow: visible;          
          }

          .filter-header {
            color: var(--greyscale_00);
            font-family: "Noto Sans";
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
          }
        }

        .filter-column-border {
          border-right: 1px solid rgba(var(--custom_color_27));
        }
      }

      .filter-footer {
        padding: 20px 8px 0px 8px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 100%;

        .left-section {
          display: flex;
          flex-direction: row;
          align-items: flex-start;
          color: rgba(var(--custom_color_28));
          text-align: center;
          font-family: "Noto Sans";
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          align-items: center;
          gap: 4px;

          .rotate-reload {
            transform: rotateX(180deg);
          }
        }

        .right-section {
          display: flex;
          flex-direction: row;
          align-items: flex-end;
          gap: 8px;
        }
      }
    }
  }
}

.date-range-filter {
  width: max-content;
  min-width: 100px;
  height: 32px;
  padding: 8px 6px;
  gap: 8px;
  border-radius: 4px;
  border: 1px solid var(--custom_color_16);
  align-items: center;
  display: flex;
  justify-content: center;
  color: rgba(var(--custom_color_17));
  text-align: center;
  font-family: "Noto Sans";
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  white-space: nowrap;
  margin-left: 8px;

  &:hover {
    background-color: rgba(var(--custom_color_18));
  }

  &:active {
    border: 1px solid rgba(var(--custom_color_19));
    background: rgba(var(--custom_color_26));
  }
}
