@import "../../../assets/css/_utilities.scss";

.scan-network-wrapper {
  font-family: "Noto Sans";
  font-style: normal;

  .gateway-device-info {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 8px;
    align-self: stretch;
    font-size: toRem(16px);
    line-height: toRem(32px);

    .gateway-device-icon {
      img {
        height: 30px;
        width: 30px;
      }
    }

    .gateway-device-title {
      color: var(--brand_black);
      font-weight: 600;
    }

    .added-device-count-info {
      color: rgba(var(--custom_color_28));
      font-weight: 400;
      line-height: normal;
    }
  }

  .tabs-container {
    .tabs-list {
      display: flex;
      gap: 30px;
      border-bottom: 1px solid rgba(var(--custom_color_26));

      .nav-item {
        display: inline-flex;
        align-items: flex-start;
        gap: 10px;

        .nav-link {
          padding: 12px 0px;
          color: rgba(var(--custom_color_24));
          text-align: center;
          font-size: toRem(16px);
          font-weight: 400;
          line-height: toRem(22px);
          border: none;

          &.active {
            border: none;
            color: var(--brand_black);
            font-weight: 700;
            border-bottom: 2px solid var(--dark_orange);
            &:hover {
              border-bottom: 2px solid var(--dark_orange);
            }
          }
          &:active,
          &:hover {
            border: none;
          }
        }
      }
    }
  }

  .header-btn-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 32px;

    .left-btn-container {
      display: flex;
      justify-content: flex-start;
      gap: 12px;

      .selected-item-label {
        display: flex;
        align-items: center;
        gap: 3px;
        color: var(--hGreyscale_40);
        font-size: toRem(14px);
        font-weight: 400;

        .item-count {
          color: var(--dark_orange);
          font-weight: 700;
        }
      }
    }
    .right-btn-container {
      display: flex;
    }
  }

  .scan-devices-container {
    .grip-drag {
      background-color: var(--brand_primary);
      opacity: 1;
      width: 1px;
      border-color: var(--brand_primary);
    }

    .grip-handle {
      margin-left: -2px;
      z-index: 101;
    }
    .grip-container {
      width: auto !important;
    }

    .table-responsive {
      margin-top: 8px;
      padding: 0;
      height: calc(100vh - 325px - 150px);
      overflow-y: auto;

      .table thead,
      .table tbody,
      .table tfoot,
      .table tr,
      .table td,
      .table th {
        height: 48px;
      }

      .table {
        min-width: 1510px;
        border: 1px solid rgba(var(--custom_color_26));
        border-radius: 8px;
        border-spacing: 0;
        height: auto;

        thead {
          position: sticky;
          left: 0;
          top: 0;
          z-index: 100;
        }

        tbody {
          tr {
            background: initial;
            height: 48px;
            padding: 0px 20px 0px 12px;
            border-style: solid solid solid solid;
            border: 1px solid var(--custom_color_23);
            cursor: auto;

            .hover-icons {
              visibility: hidden;
            }
            .truncated-device-name {
              display: flex;
              align-items: center !important;
            }

            &:hover {
              background-color: rgba(var(--custom_color_18));

              .hover-icons {
                cursor: pointer;
                visibility: visible;
                // display: flex;
                align-items: center;

                svg {
                  padding: 1px;
                }
              }
            }

            th,
            td {
              height: 48px;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;

              &.device-name-td {
                display: flex;
                align-items: center;

                .edit-name-container {
                  margin-top: 0px;
                }
              }
              .searchStyles {
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;
                max-width: 90%;
              }
            }

            td {
              border-radius: unset;
              &:last-child {
                padding-right: 20px;
              }
              svg {
                width: auto;
                height: auto;
                flex: none;
              }
              .margin-rt {
                margin-right: 8px;
              }
            }

            &:last-child {
              border: none;

              td {
                border: none;
              }

              .loader-container {
                text-align: center;
                background-color: var(--brand_white);
                height: calc(100vh - 550px);

                .site-spinner {
                  padding: 2.5px;
                }

                .loading-text {
                  color: rgba(var(--custom_color_28));
                  text-align: center;
                  font-size: toRem(16px);
                  font-weight: 400;
                  line-height: toRem(22px);
                  margin-top: 10px;
                }
              }

              .no-device-container {
                text-align: center;
                background-color: var(--brand_white);
                height: calc(100vh - 550px);

                .no-device-content {
                  display: flex;
                  flex-direction: column;
                  text-align: center;
                  font-family: "Noto Sans";
                  font-style: normal;
                  color: rgba(var(--custom_color_28));
                  padding: 0px;

                  .no-device-heading {
                    font-size: toRem(20px);
                    font-weight: 700;
                    line-height: normal;
                  }
                  .no-device-text {
                    font-size: toRem(16px);
                    font-weight: 400;
                    line-height: toRem(22px);
                  }
                }
              }
            }
          }
        }
      }
    }

    .table thead,
    .table th {
      padding: 0px 0px 0px 10px;
      gap: 10px;
      background: var(--custom_color_22);
      color: var(--text_color);
      align-items: center;
      text-align: start;
      vertical-align: middle;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      &:first-child {
        border-top-left-radius: 8px;
      }

      &:last-child {
        border-top-right-radius: 8px;
      }

      .without-border {
        width: 100%;
        gap: 4px;
        height: 19px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .with-border {
        width: 100%;
        gap: 4px;
        height: 19px;
        border-right: 1px solid var(--custom_color_23);
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
}

.primary-btn {
  font-family: "Noto Sans";
  font-size: toRem(14px);
  font-style: normal;
  font-weight: 400;
  width: max-content;
  min-width: 100px;
  height: 32px;
  padding: 8px 16px 8px 16px;
  gap: 8px;
  border-radius: 4px;
  border: 1px solid var(--custom_color_16);
  align-items: center;
  display: flex;
  justify-content: center;
  color: rgba(var(--custom_color_17));
  text-align: center;
  line-height: normal;
  white-space: nowrap;

  &.dark {
    border: none;
    background: rgba(var(--custom_color_27));
  }

  &.cancel-btn {
    border: none;
    background: rgba(var(--custom_color_27));
  }

  &:hover {
    background-color: rgba(var(--custom_color_18));
  }

  &:active {
    border: 1px solid rgba(var(--custom_color_19));
    background: rgba(var(--custom_color_26));
  }

  &.disabled {
    pointer-events: none;
    cursor: not-allowed;
    opacity: 0.5;
  }
}

.scan-network-modal {
  .modal-dialog {
    min-width: 85%;
    .modal-content {
      .modal-header {
        padding: 0px;
        align-items: center;

        .modal-title {
          height: 36px;
          line-height: toRem(36px);
        }

        .site-modal-close {
          color: rgba(var(--custom_color_32));
        }
      }

      .modal-body {
        min-height: calc(100vh - 325px);
        padding-top: 12px;
      }

      .modal-footer {
        border: none;
        padding: 16px 0px 0px 0px;

        * {
          margin: 0px;
        }
      }
    }
  }
}

.gateway-operations-popup {
  .modal-dialog {
    .modal-content {
      border-radius: 16px;
      box-shadow: 8px 12px 20px 0px rgba(0, 0, 0, 0.16);
      padding: 1rem 1.5rem 1.5rem 1.5rem !important;

      .modal-header {
        padding-bottom: 0px;
      }

      .modal-body {
        overflow: visible;
        padding: 24px 0px;
      }

      .modal-footer {
        border: none;
        padding: 0px 0px 0px 0px;
      }
    }
  }
}

input[type="checkbox"] {
  height: 16px;
  width: 16px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 2px;
  border: 1.5px solid var(--greyscale_80);
  background: initial;
  appearance: none;
  cursor: pointer;
  position: relative;
  margin-right: 10px;
}

input[type="checkbox"]:disabled {
  height: 16px;
  width: 16px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 2px;
  border: 1.5px solid var(--greyscale-40);
  background: var(--greyscale_48);
  appearance: none;
  cursor: auto;
  margin-right: 10px;
}

input[type="checkbox"]:checked::after {
  position: absolute;
  width: 16px;
  height: 16px;
  color: var(--brand_white);
  content: "\2713";
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  border: none;
  border-radius: 2px;
  background: var(--primary_40);
  cursor: pointer;
}

.gateway-operations-popup {
  .modal-dialog.modal-md.modal-dialog-centered {
    .modal-content {
      .modal-header {
        padding-bottom: 0px !important;
      }

      .modal-body {
        padding: 24px 0;

        .modal-form {
          &.authenticate-modal {
            .label {
              flex: 2 0;
              color: rgba(var(--custom_color_17));
              font-size: toRem(14px);
              font-style: normal;
              font-weight: 400;
              line-height: toRem(24px);
            }

            .row {
              display: flex;
              justify-content: space-between;
              align-items: center;
              flex: 3 0;

              .error {
                margin: 0px;
              }

              .icon-after-input {
                display: flex;
                justify-content: right;
                gap: 8px;
                height: 32px;

                svg {
                  height: 20px;
                  width: 20px;
                  padding: 3px;
                  cursor: pointer;
                  fill: var(--brand_black);
                }

                .password-show-hide {
                  padding: 0px;
                  fill: none;

                  path {
                    stroke: var(--brand_black);
                  }
                }
              }
            }

            .field-container {
              display: flex;
              align-items: center;
              margin-top: 12px;

              &:first-child {
                margin-top: 0px;
              }

              .form-control {
                text-indent: 3px;
              }
            }

            .form-control {
              width: 100%;
              height: 32px;
              border-radius: 4px;
              border: 1px solid rgba(var(--custom_color_19)) !important;
              padding: 6px 8px;
              margin-bottom: 0px;
            }

            .placeholder-wrapper {
              padding: 6px 8px;
              height: 32px;
              left: 16px;
            }

            .save-id-pwd {
              display: flex;
              margin-top: 12px;

              .blank-div {
                flex: 2 0 0;
              }

              .save-checkbox-container {
                flex: 3 0 0;
                display: flex;
                align-items: center;
                padding-left: 1rem;

                .save-check-label {
                  color: rgba(var(--custom_color_17));
                  font-size: 14px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: normal;
                }
              }
            }
          }
        }
      }

      // .modal-footer {
      //   padding-top: 0px !important;
      // }
    }
  }
}

.edit-name-container {
  width: 234px;
  height: 32px;
  padding: 5px 4px 6px 8px;
  gap: 2px;
  text-align: center;
  flex-direction: row;
  align-items: center;
  align-self: stretch;
  border-radius: 4px;
  border: 1px solid var(--brand_black);
  background: initial;
  position: relative;
  justify-content: space-between;
  margin-top: 8px;

  .cross-icon {
    z-index: 9;
    position: absolute;
    right: 4px;
    top: 5px;
  }

  .rename-input {
    padding: 0px 20px 1px 0px;
    color: var(--brand_black);
    font-size: toRem(14px);
    font-style: normal;
    font-weight: 400;
    line-height: toRem(20px);
    width: 220px;
    border: none;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    &:hover {
      background: initial !important;
      color: var(--brand_black) !important;
      border-color: var(--dark_orange);
      outline: 0;
    }

    &:focus {
      background: initial !important;
      color: var(--brand_black) !important;
      outline: 0;
    }
  }
}

.area-selector {
  // max-width: 320px;
  // min-width: 270px;
  width: 320px;
  height: 31px;

  &-toggle {
    &.dropdown-toggle {
      display: flex;
      padding: 6px 8px;
      flex-direction: row;
      align-items: flex-start;
      justify-content: space-between;
      gap: 8px;
      align-self: stretch;
      width: 100%;
      border: 1px solid var(--custom_color_16) !important;
      border-radius: 4px !important;
      background: initial;
      color: var(--custom_color_21);
      font-family: "Noto Sans";
      font-size: 14px !important;
      font-weight: 400;
      line-height: 20px !important;
      max-width: 320px;
      min-width: 320px;
      height: 32px;

      &:active,
      &:focus,
      &:focus-visible,
      &:focus-within,
      &:hover {
        border: 1px solid var(--custom_color_16) !important;
        border-radius: 4px !important;
        background: initial !important;
        color: var(--custom_color_19) !important;
      }

      &.btn-outline-secondary {
        &.show {
          border: 1px solid var(--custom_color_16) !important;
          border-radius: 4px !important;
          background: initial;
          color: var(--custom_color_19);
        }
      }

      &::after {
        display: none !important;
      }
    }
  }

  &-menu {
    overflow-y: auto;
    max-height: 300px;

    &.dropdown-menu {
      width: 100%;
      padding: 8px 0px;
      background: var(--brand_white);

      &.show {
        box-shadow: 0px 10px 15px -3px rgba(var(--greyscale-custom1), 0.1),
          0px 4px 6px -2px rgba(var(--greyscale-custom1), 0.05);
        border-radius: 4px;
      }
    }

    &-item {
      &.dropdown-item {
        width: 100%;
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        border: none;
        background: initial;
        color: var(--brand_black);
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        &.active {
          background: var(--hGreyscale_88);
          color: var(--brand_black);
          font-weight: 700;
        }

        &:first-child {
          border-top-left-radius: 12px;
          border-top-right-radius: 12px;
        }

        &:last-child {
          border-bottom-left-radius: 12px;
          border-bottom-right-radius: 12px;
        }
      }

      &.dropdown-item:hover {
        background: var(--primary_01);
        border-top-left-radius: 0px;
        border-top-right-radius: 0px;
      }
    }
  }

  .area-selected {
    &-wrapper {
      display: inline-flex;
      align-items: center;
    }

    &-name {
      margin-left: 0px !important;
    }
  }
}

.success-text {
  color: var(--custom_color_25);
  font-size: toRem(14px);
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.normal-text {
  color: var(--brand_black);
  font-size: toRem(14px);
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.loader-container {
  text-align: center;
  background-color: transparent;
  z-index: 1;
  height: calc(100vh - 550px);

  .site-spinner {
    padding: 2.5px;
  }

  .loading-text {
    color: rgba(var(--custom_color_28));
    text-align: center;
    font-size: toRem(16px);
    font-weight: 400;
    line-height: toRem(22px);
    margin-top: 10px;
  }
}

.left-50 {
  left: 50%;
}

.main-area-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 24px 18px 24px 28px;
  background: rgba(var(--custom_color_18));
  border-radius: 10px;
}

.limit-reached-text {
  color: var(--custom_color_33);
  font-size: toRem(16px);
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.loader-overlay {
  opacity: 0.6;
  pointer-events: none;
}

.custom-dropdown-container {
  .custom-dropdown-options {
    display: flex;
    padding: 6px 8px;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    gap: 8px;
    align-self: stretch;
    width: 100%;
    border: 1px solid var(--custom_color_16) !important;
    border-radius: 4px !important;
    background: initial;
    color: var(--custom_color_21);
    font-family: "Noto Sans";
    font-size: 14px !important;
    font-weight: 400;
    line-height: 20px !important;
    max-width: 180px;
    min-width: 160px;
    height: 32px;
    outline: none;
    cursor: pointer;
  }
}
